<template>
 <div :class="windowClass">
   <v-container>
    <v-row>
      <v-col cols="12">
                <v-card>
               <v-toolbar>
                 <v-icon left color="primary">mdi-account-multiple-check</v-icon> CLAIMS
                 <v-spacer/>

                 
                 <span style="width: 290px;">
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            dense
                            outlined
                            hide-details
                        ></v-text-field>
                 </span>
                 <span style="width: 100px; margin-left: 5px;">
                  <v-autocomplete
                  v-model="event_id"
                  :items="events"
                  item-value="id"
                  item-text="name"
                  @change="getUsers()"
                  hide-details
                  outlined
                    dense
                  label="Select Event"
                  ></v-autocomplete>
                 </span>
                 <span style="width: 100px; margin-left: 5px;">
                  <v-autocomplete
                  v-model="precinct"
                  :items="precincts"
                  hide-details
                  outlined
                    dense
                  label="Select Precinct"
                  ></v-autocomplete>
                 </span>
               </v-toolbar>
               <v-card-text>
          
                <span class="text-success font-weight-bold text-h6">EVENT: {{event_id}}</span>
                <span class="text-success font-weight-bold ml-4 text-h6">CLAIMS: {{byPrecinct.length}}</span>
                <span class="text-success font-weight-bold ml-4 text-h6">PRECINCT: {{precinct}}</span>
                <v-data-table
                    class="va-table"
                    dense
                    :headers="headers"
                    :search="search"
                    :items="byPrecinct"
                    >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item,index) in items" :key="index">
                            <td>{{ item.prec }}</td>
                            <td>{{ item.fullname }}</td>
                            <td>{{item.claimed_by}}</td>
                            <td>{{$moment(item.claimed_dt).format("YYYY-MM-DD HH:mm A")}}</td>
                            <!-- <td>{{ item.role }}</td>
                             <td :class="STATUS_COLOR(item.status)">{{ item.status }}</td>
                            <td><v-icon left small :color="statusIndicator(item.last_login)">mdi-checkbox-blank-circle</v-icon> {{ $moment(item.last_login).fromNow()}}</td> -->
                        </tr>
                        </tbody>
                    </template>
                    </v-data-table>
               </v-card-text>
             </v-card>
          </v-col>
         
    </v-row>
    </v-container>
   
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
        form: false,
        event_id: 1,
        trans_dailog: false,
        areaform: false,
        state: 'new',
        user: {},
        events: [],
        search:'',
        areas:[],
        area:{},
        voter:{},
        voters:[],
        precinct:'ALL',
        precincts:[
        "ALL",
"0001A",
"0001B",
"0001C",
"0001D",
"0002A",
"0002B",
"0003A",
"0003B",
"0003C",
"0004A",
"0004B",
"0004C",
"0004D",
"0005A",
"0005B",
"0005C",
"0005D",
"0006A",
"0006B",
"0006C",
"0007A",
"0007B",
"0007C",
"0008A",
"0008C",
"0008D",
"0008E",
"0009A",
"0009B",
"0009C",
"0010A",
"0010B",
"0010C",
"0011A",
"0011B",
"0011C",
"0012A",
"0012B",
"0012C",
"0013A",
"0013B",
"0013C"
],
        headers: [
          { text: 'Prec', value:"prec"},
            { text: 'Name', value:"fullname"},
             { text: 'Claimed By', value:"claimed_by"},
             { text: 'ClaimedDT', value:"claimed_dt"}
        ]
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
          this.getUsers()
          this.getEvents()
         
        }
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
      byPrecinct() {
        if(this.precinct == "ALL") {
          return  this.voters
        }
        var voters = this.voters.reduce((res,item)=>{
          if(item.prec == this.precinct) {
            res.push(item)
          }
          return res
        },[])

        return voters
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      transEvent(){
        this.trans_dailog = false
      },
      statusIndicator(_time) {
          //var current = this.$moment(_time)
          var a = this.$moment()
            var b = this.$moment(_time)
           var diff = a.diff(b, 'hour')
           if(diff <=  3) {
              return 'success'
           } else if(diff > 3 && diff < 5) {
               return '#A5D6A7'
           } else {
             return '#989898'
           }
      },
      CountBarangay(area){
          var _count = area.reduce((res,item)=>{
                res += item.barangays.length
                return res
          },0) 
          return _count
      },
      afEvent() {
        this.areaform = false
        this.getAreas()
      },
      formEvent(data){
          this.form = false
          if(data.action != 'close') {
            console.log(data)
              this.voter = data.voter
              this.Save()
          }
      },

    
      getEvents: function() { 
        this.$http.get("event/list").then(response => {
            response.data.items != null?this.events = response.data.items:this.events =[]
        }).catch(e => {
        console.log(e.data)
        })
     },
      getUsers: function() { 
        this.voters = []
        this.$http.post("claims/list", {event_id: this.event_id}).then(response => {
          console.log(response.data)
            response.data.list != null?this.voters = response.data.list:this.voters =[]
        }).catch(e => {
        console.log(e.data)
        })
     },
    
      Save: function() {
        let method = 'voter/register'
        this.state != 'new'?method='voter/update':method = 'voter/register'
        console.log(method, this.voter)
        this.$http.post(method, this.voter).then(response => {
            response.data.status? this.setAlertColor('success'): this.setAlertColor('error')
            this.setAlertText(response.data.message)
            this.setAlert(true)
            this.getUsers()
        }).catch(e => {
        console.log(e)
        })
     },
    }
  }
</script>